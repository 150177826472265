@import '../../../../scss/theme-bootstrap';

.basic-carousel-formatter {
  position: relative;
  width: 100%;
  max-width: $max-width-xlarge;
  margin: auto;
  &.basic-carousel-formatter--content-block-arrows {
    @include breakpoint($small-down) {
      .mantle-media-asset {
        position: relative;
      }
      .slick-prev,
      .slick-next {
        opacity: 0;
        transition: all 0.3s;
        display: flex;
        align-items: center;
      }
      .slick-active .slick-prev,
      .slick-active .slick-next {
        opacity: 1;
      }
      .slick-prev {
        transform: translateX(-190%);
      }
      .slick-next {
        transform: translateX(190%);
        // The next arrow doesn't align properly
        #{$rdirection}: 0;
        position: absolute;
        &:before {
          position: static;
          margin-#{$ldirection}: 8px;
        }
      }
      .content-block {
        overflow: visible;
      }
    }
  }
  &.basic-carousel-formatter--equal-height {
    .slick-track {
      display: flex !important;
    }
    .slick-slide {
      height: inherit !important;
    }
  }
  .content-block__content-wrapper {
    transform: translateZ(0);
  }
  .slick-dots {
    bottom: 20px;
    li {
      width: 17px;
      margin: 0;
      button:before {
        font-size: 30px;
        color: $color-very-light-pink;
      }
    }
  }
  // https://github.com/kenwheeler/slick/issues/1961
  .slick-track,
  .slick-list {
    -webkit-backface-visibility: hidden;
  }
  .slick-arrows {
    position: absolute;
    width: 100%;
    height: 100%;
    #{$ldirection}: 0;
    top: 0;
  }
  .slick-prev,
  .slick-next {
    line-height: 1;
    top: 50%;
    margin-top: 0;
    transform: translateY(-50%);
    font-size: 0;
    &:before {
      font-size: 20px;
    }
  }
  &.basic-carousel--2,
  &.basic-carousel--3,
  &.basic-carousel--4 {
    .basic-carousel__slide {
      @include breakpoint($landscape-up) {
        margin: 0 5px;
      }
    }
  }
  .basic-carousel {
    display: none; // Prevent FOUC
    &.slick-initialized {
      display: block;
    }
  }
  &--no-peeking[data-slides='1'] {
    .basic-carousel {
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      > .basic-carousel__slide {
        flex-shrink: 0;
        overflow: hidden;
        display: inline-block;
        width: 100%;
      }
      &.slick-initialized {
        display: block;
      }
    }
  }
  .slick-slide {
    @include breakpoint($landscape-up) {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }
  &.basic-carousel-formatter--no-peeking {
    .slick-slide {
      padding: 0;
    }
    .mobile-arrow-top-25 {
      .slick-prev,
      .slick-next {
        top: 25%;
        transform: translateY(-25%);
        @include breakpoint($landscape-up) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  &.basic-carousel-formatter--peeking {
    .slick-slide {
      padding: 0px 10px;
      @include breakpoint($landscape-up) {
        padding: 0;
      }
    }
    .mobile-arrow-top-25 {
      .slick-prev,
      .slick-next {
        top: 25%;
        transform: translateY(-25%);
        @include breakpoint($landscape-up) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .slick-prev {
        #{$ldirection}: 2.7rem;
        @include breakpoint($landscape-up) {
          #{$ldirection}: 0;
        }
      }
      .slick-next {
        #{$rdirection}: 2.7rem;
        @include breakpoint($landscape-up) {
          #{$rdirection}: 0;
        }
        &:before {
          #{$rdirection}: 0;
        }
      }
    }
  }
  &.basic-carousel-formatter--pc-peeking {
    @include breakpoint($landscape-up) {
      .slick-prev {
        #{$ldirection}: 2%;
      }
      .slick-next {
        #{$rdirection}: 2%;
        &::before {
          #{$rdirection}: 0;
        }
      }
      .slick-slide {
        opacity: 1;
        padding: 0 25px;
      }
      .padding--bare {
        padding: 36px 0 40px;
      }
    }
  }
  &--overlap-style {
    @include breakpoint($medium-down) {
      .content-block--hero {
        .position-image,
        .position--bare {
          .content-block__content-wrapper {
            position: static;
          }
        }
      }
      .basic-carousel__container {
        position: relative;
      }
      .carousel-dots {
        position: absolute;
        margin-top: 40px;
        width: 100%;
      }
      .slick-dots {
        li {
          button:before {
            color: $color-black;
          }
        }
      }
      .content-block {
        &__content-buttons {
          &.align-links--bare {
            text-align: center;
          }
        }
      }
    }
  }
  .slick-active {
    @include breakpoint($landscape-up) {
      opacity: 1;
    }
  }
  .carousel-controls {
    &--color-black {
      .slick-prev,
      .slick-next {
        &:before {
          color: $color-black;
        }
      }
    }
    &--color-white {
      .slick-prev,
      .slick-next {
        &:before {
          color: $color-white;
        }
      }
    }
    &--color-grey {
      .slick-prev,
      .slick-next {
        &:before {
          color: $color-warm-grey;
        }
      }
    }
  }
  &--max-width-768 {
    max-width: $tablet;
  }
  &--max-width-1024 {
    max-width: $max-width;
  }
  &--max-width-1280 {
    max-width: $max-width-medium;
  }
  &--max-width-1440 {
    max-width: $max-width-large;
  }
  &--max-width-1920 {
    max-width: $max-width-xlarge;
  }
}
